const DispatchlistRouter = {
  route: null,
  name: null,
  title: '发货单管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/dispatchlist/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '发货单表头导入',
      type: 'view',
      name: 'dispatchlist',
      route: '/dispatchlist/list',
      filePath: 'view/chinaweise/dispatchlist.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['导入发货单'],
    },
    {
        title: '领料申请单表头导入',
        type: 'view',
        name: 'materialappvouch',
        route: '/materialappvouch/list',
        filePath: 'view/chinaweise/materialappvouch.vue',
        inNav: true,
        icon: 'iconfont icon-tushuguanli',
        permission: ['导入领料申请单'],
      },
      {
        title: '发货单列表管理',
        type: 'view',
        name: 'fhdispatchlist',
        route: '/fhdispatchlist/list',
        filePath: 'view/chinaweise/fhdispatchlist.vue',
        inNav: true,
        icon: 'iconfont icon-tushuguanli',
        permission: ['查询发货单列表'],
      },
  ],
}

export default DispatchlistRouter
