const QMCheckVoucherRouter = {
  route: null,
  name: null,
  title: '质量管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/qm-check-voucher/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '检验单列表',
      type: 'view',
      name: 'qm-check-voucher',
      route: '/qm-check-voucher/list',
      filePath: 'view/chinaweise/qm-check-voucher.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['检验单列表'],
    },
  ],
}

export default QMCheckVoucherRouter
