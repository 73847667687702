const CurrentStockRouter = {
  route: null,
  name: null,
  title: '现存量货位存量',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/current-stock/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '现存量管理',
      type: 'view',
      name: 'current-stock',
      route: '/current-stock/list',
      filePath: 'view/chinaweise/current-stock.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询现存量'],
    },
    {
      title: '货位存量管理',
      type: 'view',
      name: 'invposition-sum',
      route: '/invposition-sum/list',
      filePath: 'view/chinaweise/invposition-sum.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询货位存量'],
    },
  ],
}

export default CurrentStockRouter
