const InventoryRouter = {
  route: null,
  name: null,
  title: '存货档案/分类',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/invent-tory/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '存货档案管理',
      type: 'view',
      name: 'invent-tory',
      route: '/invent-tory/list',
      filePath: 'view/chinaweise/invent-tory.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询存货档案'],
    },
  ],
}

export default InventoryRouter
