const PrexectionRouter = {
  route: null,
  name: null,
  title: '请购执行进度管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/prexection/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '请购执行进度',
      type: 'view',
      name: 'prexection',
      route: '/chinaweise/list',
      filePath: 'view/chinaweise/prexecution.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询请购执行单列表'],
    },
  ],
}

export default PrexectionRouter
