const CurrentAccountRouter = {
  route: null,
  name: null,
  title: '出入库流水账',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/current-qc-account/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '采购入库流水账',
      type: 'view',
      name: 'current-cr-account',
      route: '/current-cr-account/list',
      filePath: 'view/chinaweise/current-cr-account.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询采购入库流水账'],
    },
    {
      title: '产成品入库流水账',
      type: 'view',
      name: 'current-sr-account',
      route: '/current-sr-account/list',
      filePath: 'view/chinaweise/current-sr-account.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询生产入库流水账'],
    },
    {
      title: '材料出库流水账',
      type: 'view',
      name: 'current-lc-account',
      route: '/current-lc-account/list',
      filePath: 'view/chinaweise/current-lc-account.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询材料出库流水账'],
    },
    {
      title: '销售出库流水账',
      type: 'view',
      name: 'current-xc-account',
      route: '/current-xc-account/list',
      filePath: 'view/chinaweise/current-xc-account.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询销售出库流水账'],
    },
    {
      title: '其他出库流水账',
      type: 'view',
      name: 'current-qc-account',
      route: '/current-qc-account/list',
      filePath: 'view/chinaweise/current-qc-account.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询其他出库流水账'],
    },
    {
      title: '其他入库流水账',
      type: 'view',
      name: 'current-qr-account',
      route: '/current-qr-account/list',
      filePath: 'view/chinaweise/current-qr-account.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查询其他入库流水账'],
    },
  ],
}

export default CurrentAccountRouter
